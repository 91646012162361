import API_CODE from "@/assets/common/ApiCode.js";
import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

import useHallRepository from "@/repositories/hall";
import useHallSettingRepository from "@/repositories/hall-setting";
import useMediaRepository from "@/repositories/media";

import * as commonTypes from "@/store/common/mutationTypes";

const actions = {
  /**
   * 初期値の取得
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    const fetchActions = [];
    if (getters["common/isAuthorized"]({ name: "settings", type: "view" })) {
      fetchActions.push("setHallInitSetting");
    }
    if (getters["common/isAuthorized"]({ name: "mediaAccounts", type: "view" })) {
      fetchActions.push("setAccount");
    }
    const success = await Promise.all(fetchActions.map(async (v) => await dispatch(v)));
    if (success.length == 0 || success.every((v) => v == true)) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("modal/showErrorModal", {
        text: state.modal.errorModal.text || MESSAGE_CONFIG.error,
      });
    }
  },

  /**
   * 式場基本設定のデータを state に保存
   */
  async setHallInitSetting({ commit }) {
    const { index } = useHallSettingRepository();
    const response = await index();
    if (!response || !response.data) {
      return false;
    }
    commit("SET_HALL_INIT_SETTING", response.data.data);
    commit("SET_HALL_SETTING_MASTER", response.data.master);
    return true;
  },

  /**
   * 媒体設定のデータを state に保存
   */
  async setAccount({ commit }) {
    const { index } = useMediaRepository();
    const response = await index();
    if (!response || !response.data) {
      return false;
    }
    commit("SET_MEDIA_ACCOUNT", response.data.data);
    return true;
  },

  /**
   * インポート準備完了
   */
  async ready({ commit, dispatch }) {
    commit("modal/setUpdateModal", { isShow: true });
    const { readyForImport } = useHallRepository();
    const { status } = await readyForImport();
    if (status !== API_CODE.response.success) {
      dispatch("modal/showErrorModal", { isShow: true, text: MESSAGE_CONFIG.error });
      return;
    }
    commit("modal/setCompleteText", "インポートを開始します");
    dispatch("modal/showCompleteModal", { path: "/" });
  },
};

export default actions;
