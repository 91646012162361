import { getCookieAccount } from "@/lib/cookie";

import Repository from "./repository";

export default () => {
  const { hall_id, token, userable_id } = getCookieAccount();
  const hallId = hall_id || userable_id;
  const resource = "link-notices";
  const { instance } = Repository(`${hallId}/${resource}`, token);
  return {
    confirm: (id) => instance.post(`${id}/confirm`),
  };
};
