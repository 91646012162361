<template>
  <div>
    <modal-loading v-if="loading" />
    <div>
      <top-admin v-if="isShowAdmin" />
      <top-init v-else-if="isShowInit" />
      <top-company v-else-if="isShowCompany" />
      <top v-else />
    </div>
    <modal-state />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";
import { getSelectedHallId } from "@/assets/common/PageConfig.js";

import router from "@/pages/top/router";

import ModalLoading from "@/components/molecules/common/ModalLoading.vue";
import ModalState from "@/components/organisms/common/ModalState.vue";
import Top from "@/components/organisms/top/Top.vue";
import TopInit from "@/components/organisms/top/init/TopInit.vue";
import TopAdmin from "@/components/organisms/top/admin/TopAdmin.vue";
import TopCompany from "@/components/organisms/top/company/TopCompany.vue";

export default {
  components: {
    ModalLoading,
    ModalState,
    Top,
    TopInit,
    TopAdmin,
    TopCompany,
  },
  computed: {
    ...mapState("common", { loading: (state) => state.loading }),
    ...mapGetters({
      account: "common/account",
      hall: "common/hall",
      isViewableOwnHall: "common/isViewableOwnHall",
      isViewableAnyHall: "common/isViewableAnyHall",
    }),
    isShowAdmin() {
      if (getSelectedHallId()) {
        // 管理アカウント->式場アカウント
        return false;
      }
      return this.isViewableAnyHall;
    },
    isShowInit() {
      if (!this.account) {
        // アカウント情報が取得できていない場合は表示しない
        return false;
      }
      const { userable_type } = this.account;
      if (userable_type === "Admin") {
        // 管理アカウントの場合は表示しない
        return false;
      }
      // TODO: 企業アカウントの場合の表示条件を追加する
      const { password_changed_at, account_created_at } = this.hall;
      return password_changed_at === null || account_created_at === null;
    },
    isShowCompany() {
      if (getSelectedHallId()) {
        // 企業アカウント->式場アカウント
        return false;
      }
      return this.isViewableOwnHall;
    },
  },
  mounted() {
    const route = router.currentRoute.value;
    if ("permission" in route.query) {
      this.showErrorModal({
        text: MESSAGE_CONFIG.response.accessDenied,
      });
      this.$router.push(location.pathname); // query 削除
    }
  },
  methods: {
    ...mapActions({
      showErrorModal: "modal/showErrorModal",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
