import MESSAGE_CONFIG from "@/assets/common/MessageConfig.js";

import useNewsRepository from "@/repositories/news";
import useHallAdminRepository from "@/repositories/hall-admin";

import * as commonTypes from "@/store/common/mutationTypes";

const actions = {
  /*
   * 初期データを受け取る
   ***********************************/
  /**
   * 初期値の取得
   */
  async getInitialData({ commit, dispatch, state, getters }) {
    await dispatch("common/checkPagePermission");
    const fetchActions = [];
    fetchActions.push("setNewsList");
    if (getters["common/isAuthorized"]({ name: "halls", type: "view" })) {
      fetchActions.push("setHallList");
    }
    const success = await Promise.all(fetchActions.map(async (v) => await dispatch(v)));
    if (success.every((v) => v == true)) {
      commit("common/" + commonTypes.SET_LOADING_FINISH);
    } else {
      commit("common/" + commonTypes.ERROR);
      dispatch("modal/showErrorModal", {
        text: state.modal.errorModal.text || MESSAGE_CONFIG.error,
      });
    }
  },

  /*
   * 初期データを state に反映
   ***********************************/
  // お知らせ & 稼働状況
  async setNewsList({ commit }) {
    const { showPublishedNews } = useNewsRepository();
    const { data } = await showPublishedNews();
    if (!data) return false;
    commit("SET_NEWS_DATA", { data: data.data });
    commit("SET_NEWS_MASTER", { master: data.master });
    return true;
  },
  // 式場一覧
  async setHallList({ commit }) {
    const { index } = useHallAdminRepository();
    const { data } = await index();
    if (!data) return false;
    commit("SET_HALL_DATA", { data: data.data.halls });
    return true;
  },
};

export default actions;
